import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import MainPageComponent from "./MainPage/MainPage.component"
import { PrivateRoute } from "./PrivateRoute/PrivateRoute"
import { PATH_ROOT, PATH_HIDDEN_REGISTRATION } from "../const/routes.const"
import CRM from "./CRM/CRM.component"
import Footer from "./common/Footer/Footer.component"

export default function Router(): JSX.Element {
  const showRegistrationTitle = false

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PATH_ROOT}>
          <MainPageComponent isHidden={showRegistrationTitle} />
          <Footer />
        </Route>
        <Route exact path={PATH_HIDDEN_REGISTRATION}>
          <MainPageComponent isHidden={showRegistrationTitle} />
          <Footer />
        </Route>
        <PrivateRoute
          path={PATH_ROOT}
          component={CRM}
        />
      </Switch>
    </BrowserRouter>
  )
}
