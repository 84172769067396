import React, { FC } from 'react'
import EvaluateFormContainer from '../../../../containers/CRM/SelectionExpertCRM/EvaluateForm.container'
import 
  SelectionExpertHeaderContainer 
from 
  '../../../../containers/CRM/SelectionExpertCRM/SelectionExpertHeader.container'
import {
  SelectionExpertArtistGetResponse
} from '../../../../models/SelectionExpertsModels/selectionExpertEvaluation.model'
import Footer from '../../../common/Footer/Footer.component'
import LoaderComponent from '../../../common/Loader/Loader.component'
import ArtistInfo from './ArtistInfo/ArtistInfo.component'

import './style.sass'

interface Props {
  isLoading?: boolean;
  artistData?: SelectionExpertArtistGetResponse;
  onBackClick: () => void;
}

const block = 'selection-expert-evalution'

const SelectionExpertEvaluationComponent: FC<Props> = (props) => {
  const { isLoading, artistData, onBackClick } = props

  return (
    <div className={block}>
      <SelectionExpertHeaderContainer />

      <div className={`${block}__body`}>
        {isLoading ? (
          <LoaderComponent width={100} height={100} />
        ) : (artistData && (
          <>
          <div 
            className={`${block}__artist-header`}
            style={{
              backgroundImage: `url(${artistData?.photoSiteHeader.url})`
            }}
          >
            <div className={`${block}__artist-header-rift`} />
          </div>
          
          <div className={`${block}__content`}>
            <div className="container row row--space-between row--align-top">
              <ArtistInfo onBackClick={onBackClick} artistData={artistData} />
              <EvaluateFormContainer artistData={artistData}/>
            </div>
          </div>
          </>
        ))}        
      </div>

      <Footer />
    </div>
  )
}

export default SelectionExpertEvaluationComponent