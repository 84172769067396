import { autoserialize, autoserializeAs } from "cerialize"
import { FileUploadResponse } from "../common/fileUpload.model"

export class SelectionExpertArtistGenre {
  @autoserialize label: string;
  @autoserialize name: string;

  constructor(label: string, name: string) {
    this.label = label
    this.name = name
  }
}

export class SelectionExpertArtistGetResponse {
  @autoserialize id: number;
  @autoserialize name: string;
  @autoserializeAs(SelectionExpertArtistGenre)
  genre: SelectionExpertArtistGenre;
  @autoserialize subgenre: string;
  @autoserialize country: string;
  @autoserialize city: string;
  @autoserializeAs("contact_name") contactName: string;
  @autoserialize description: string;
  @autoserialize experience: string;
  @autoserializeAs("facebook_url") facebookUrl: string;
  @autoserializeAs("instagram_url") instagramUrl: string;
  @autoserializeAs("vkontakte_url") vkontakteUrl: string;
  @autoserializeAs("soundcloud_url") soundcloudUrl: string;
  @autoserializeAs("tiktok_url") tiktokUrl: string;
  @autoserializeAs("spotify_url") spotifyUrl: string;
  @autoserializeAs("video_urls") videoUrls: string[];
  @autoserializeAs("audio_urls") audioUrls: string[];
  @autoserializeAs(FileUploadResponse, "photo_site_header")
  photoSiteHeader: FileUploadResponse;

  constructor(
    id: number,
    name: string,
    genre: SelectionExpertArtistGenre,
    subgenre: string,
    country: string,
    city: string,
    contactName: string,
    description: string,
    experience: string,
    facebookUrl: string,
    instagramUrl: string,
    vkontakteUrl: string,
    soundcloudUrl: string,
    tiktokUrl: string,
    spotifyUrl: string,
    videoUrls: string[],
    audioUrls: string[],
    photoSiteHeader: FileUploadResponse
  ) {
    this.id = id
    this.name = name
    this.genre = genre
    this.subgenre = subgenre
    this.country = country
    this.city = city
    this.contactName = contactName
    this.description = description
    this.experience = experience
    this.facebookUrl = facebookUrl
    this.instagramUrl = instagramUrl
    this.vkontakteUrl = vkontakteUrl
    this.soundcloudUrl = soundcloudUrl
    this.tiktokUrl = tiktokUrl
    this.spotifyUrl = spotifyUrl
    this.videoUrls = videoUrls
    this.audioUrls = audioUrls
    this.photoSiteHeader = photoSiteHeader
  }
}

export class SelectionExpertArtistEvaluateRequest {
  @autoserializeAs("music_rating") musicRating: number;
  @autoserializeAs("emotional_rating") emotionalRating: number;
  @autoserializeAs("concert_activities_rating") concertActivitiesRating: number;

  constructor(
    musicRating: number,
    emotionalRating: number,
    concertActivitiesRating: number
  ) {
    this.musicRating = musicRating
    this.emotionalRating = emotionalRating
    this.concertActivitiesRating = concertActivitiesRating
  }
}

export class EvaluateForm {
  @autoserializeAs("music_rating") musicRating: string;
  @autoserializeAs("emotional_rating") emotionalRating: string;
  @autoserializeAs("concert_activities_rating") concertActivitiesRating: string;

  constructor(
    musicRating: string,
    emotionalRating: string,
    concertActivitiesRating: string
  ) {
    this.musicRating = musicRating
    this.emotionalRating = emotionalRating
    this.concertActivitiesRating = concertActivitiesRating
  }
}

export class SelectionExpertArtistWithRatingGetResponse {
  @autoserializeAs(SelectionExpertArtistGetResponse, "artist_form")
  artistForm: SelectionExpertArtistGetResponse;

  @autoserializeAs(SelectionExpertArtistEvaluateRequest)
  rating: EvaluateForm;

  constructor(
    artistForm: SelectionExpertArtistGetResponse,
    rating: EvaluateForm
  ) {
    this.artistForm = artistForm
    this.rating = rating
  }
}
